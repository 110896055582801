import React, { Component } from 'react';
import './App.css';

class App extends Component {
  handleSubmit(event){ 
    event.preventDefault();
    
    if(document.getElementById("customerId").value === "" || document.getElementById("customerId").value === undefined){
        alert("Customer Id  is Manditory !");
        return;
    }else if(document.getElementById("issues").value === "" || document.getElementById("issues").value === undefined){
        alert("Number Of Issues are Manditory !");
        return;
    }
  }
  render(){ 
    
    return (
      <div className="title">
        <h3>CUSTOMER RISK MANANGEMENT</h3>
        <div className="container">
          <form>
            <div class="customerid">
            <label>Customer ID
              <span class="error">*  </span>
            <input type="text" name="customerId" id="customerId" />
            </label><br /><br />
            </div>
            <div class="issues">
            <label>Number Of Issues
            <span class="error">*  </span>
            <input type="text" name="issues" id="issues" />
            </label><br /> <br />
              </div>            
            
            <div className="btn">
              <form ref="form" onSubmit={this.handleSubmit}>
                <button type="submit" onClick={this.handleSubmit}>Submit</button>
              </form>
            </div>

          </form>
        </div>
      </div>

    );

  }

}

export default App;
